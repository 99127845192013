<template>
  <div class="row">
    <sub class="col-sm-12 location-info">
      <q-chip
        v-for="(line) in journey.line_names"
        :key="line"
        class="float-right"
        square
        color="grey-8"
        outline
        dense
        :title="$t('bus.line_num', { num: line })"
      >
        {{ line }}
      </q-chip>
      <strong>{{ journey.origin.name }}</strong> {{ $t('to') }}
      <br>
      <strong class="destination">{{ journey.destination.name }}</strong>
      <br>
    </sub>
    <p class="col-12 text-grey-7">
      <span class="leg-date">{{ journey.depart.date | date }}</span>
    </p>

    <h6 class="col-11 times">
      {{ journey.depart.date | time }}
      <q-icon name="arrow_forward" class="arrow" />
      {{ journey.arrive.date | time }}
    </h6>
    <span class="col-11 text-grey-7 leg-info">
      <a>{{ $tc('num.changes', journey.number_of_changes) }}</a>
      , {{ journey.duration.text }}
    </span>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date: value => date.toHugeDate(value),
    time: value => date.toCivilTime(value)
  },
  props: ['journey']
}
</script>

<style lang="stylus" scoped>
.location-info
  margin-bottom 8px
.location-info .destination
  font-size 20px

.leg-name
  text-transform uppercase
  font-weight 700
  font-size 15px

.leg-date
  color black
  font-size 13px

.times
  font-size 20px

.arrow
  vertical-align bottom

.leg-info
  font-size 13px

</style>
