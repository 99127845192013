import { render, staticRenderFns } from "./bus-card.vue?vue&type=template&id=24695a66&scoped=true&"
import script from "./bus-card.vue?vue&type=script&lang=js&"
export * from "./bus-card.vue?vue&type=script&lang=js&"
import style0 from "./bus-card.vue?vue&type=style&index=0&id=24695a66&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24695a66",
  null
  
)

export default component.exports